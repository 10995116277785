// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adatvedelem-tsx": () => import("./../../../src/pages/adatvedelem.tsx" /* webpackChunkName: "component---src-pages-adatvedelem-tsx" */),
  "component---src-pages-beolvas-tsx": () => import("./../../../src/pages/beolvas.tsx" /* webpackChunkName: "component---src-pages-beolvas-tsx" */),
  "component---src-pages-hny-tsx": () => import("./../../../src/pages/hny.tsx" /* webpackChunkName: "component---src-pages-hny-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

