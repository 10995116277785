import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
  colors: {
    dpcblue: {
      500: '#4373B9',
      600: '#365D95',
      faded: '#4373B98C',
    },
    dpcgreen: {
      500: '#00A76B',
      faded: '#00A76B8C',
    },
    dpcgray: {
      100: '#F6F6F6',
      300: '#CCCCCC',
      400: '#C4C4C4',
    },
  },
  fonts: {
    body: 'Montserrat',
    heading: 'Montserrat',
  },
});
